@charset "utf-8";

/* Make all footer icons black */
footer i.fa-rss-square,
.icon-black {
  color: #000 !important;
}

/* Event page calendar */
$dim-foreground: rgba(34, 40, 49, 0.44);
$emphasize-bg: #e2e4e5;
$emphasize-bg-current: #eee;
$tr-dim: rgba(226, 228, 229, 0.5);

div.calendar {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(13.5em, 1fr));
}

div.monthcard {
  border: solid 1px black;
  padding: 5%;

  h3.monthlabel {
    margin-top: .5em;
  }

  tr {
    td {
      font-size: 110%;

      &:first-child {
        text-align: end;
      }

      &:last-child {
        width: 100%;
      }
    }

    &:nth-child(even) {
      background-color: $emphasize-bg;
    }
  }

  &.dim {
    border-color: $dim-foreground;

    a,
    h3,
    td {
      color: $dim-foreground;
    }

    tr.dim {
      background-color: $tr-dim;
    }
  }

  &.current {
    background-color: $emphasize-bg;
    border-width: 2px;

    table {
      tr {
        a.dim,
        td.dim {
          color: $dim-foreground;
        }

        &:nth-child(even) {
          background-color: $emphasize-bg-current;
        }

        &.current {
          a,
          td {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@import "minimal-mistakes/skins/air"; // skin
@import "minimal-mistakes"; // main partials
